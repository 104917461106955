import { HiOutlineAcademicCap } from "react-icons/hi"

export default [

  {
    id: 'learning',
    title: 'Learning',
    icon: <HiOutlineAcademicCap />,
    navLink: 'https://learning.turia.in',
    resource: 'filemanager',
    newTab : true,
    externalLink: true,
    newTag: true
  }

]