// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@src/configs/axios/axiosConfig'

export const getTimeLineList = createAsyncThunk('appFeeds/getTimeLineList', async params => {
    const response = await axios.post('/auditlogs/timeline', params)
    return {
        data: response.data.auditlogs
    }
})

export const getAttendanceTimeLine = createAsyncThunk('appFeeds/getAttendanceTimeLine', async params => {
    const response = await axios.post('/auditlogs/attendancetimeline', params)
    return {
        data: response.data.attendances
    }
})

export const appFeedsSlice = createSlice({
    name: 'appFeeds',
    initialState: {
        data: [],
        attendanceList : []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getTimeLineList.fulfilled, (state, action) => {
                state.data = action.payload.data
            })
            .addCase(getAttendanceTimeLine.fulfilled, (state, action) => {
                state.attendanceList = action.payload.data
            })
        
    }
})

export default appFeedsSlice.reducer
