// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'

export const employeeActivity = createAsyncThunk('appDashboard/employeeActivity', async (data, { }) => {
    const response = await axios.post('dashboard/employeeActivity', data)
    return response.data.employeeActivity
})


export const appDashboardSlice = createSlice({
    name: 'appInvoice',
    initialState: {
        employeeActivityData: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(employeeActivity.fulfilled, (state, action) => {
            state.employeeActivityData = action.payload
        })

    }
})

export default appDashboardSlice.reducer
